<script setup>
    import { nextTick, watch } from 'vue'

    const props = defineProps({
        item: Object,
    })

    const answer = ref()
    const height = ref()
    const activeFaqItem = defineModel('activeFaqItem')

    watch(activeFaqItem, (activeItemId) => {
        if (activeItemId == props.item.id) {
            answer.value.style.height = 0

            nextTick(() => (answer.value.style.height = answer.value.scrollHeight + 'px'))
        } else {
            answer.value.style.height = answer.value.scrollHeight + 'px'
            nextTick(() => (answer.value.style.height = 0))
        }
    })

    const toggleActive = () => {
        activeFaqItem.value = props.item.id == activeFaqItem.value ? null : props.item.id
    }
</script>

<template>
    <div class="max-w-4xl py-3 mx-auto md:py-4 lg:py-5">
        <div class="flex justify-between gap-4 cursor-pointer question">
            <p class="flex-1 text-lg font-bold select-none" @click="toggleActive">
                {{ item.title }}
            </p>

            <button
                class="transition relative after:!bg-brand1 icon icon-plus after:inset-0 after:absolute w-4 h-4 after:!ml-0 mt-[.4em]"
                :class="{ 'rotate-45': activeFaqItem == item.id }"
                type="button"
                @click="toggleActive"
            ></button>
        </div>

        <div ref="answer" class="answer overflow-hidden transition-[height] h-0">
            <div v-html="item.answer" class="pt-3" />
        </div>
    </div>
</template>

<style scoped>
    .expand-leave-active,
    .expand-enter-active {
        overflow: hidden;
    }

    .expand-enter-to,
    .expand-leave-from {
        height: v-bind(height);
    }

    .expand-enter-from,
    .expand-leave-to {
        opacity: 0;
        height: 0;
    }

    .answer:last-child {
        margin-bottom: 0;
    }
</style>
