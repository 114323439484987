<script setup>
    import { ref } from 'vue'

    const faqs = await useStatamic().getEntries('faq')
    const activeFaqItem = ref(null)
</script>

<template>
    <h2 class="mb-6 sm:mb-8 lg:mb-16">
        Novated Lease
        <em>FAQ</em>
    </h2>

    <FaqItem
        v-for="item in faqs"
        :key="item.id"
        :item="item"
        v-model:activeFaqItem="activeFaqItem"
        class="border-b border-light"
    />
</template>
